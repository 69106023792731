import m from "mithril";
import Chatbot from "../Chatbot";
export default class MessageInput {

    constructor(
        private readonly chatbot: Chatbot
    ) { }

    private updateHeight(element: HTMLElement): void {
        element.style.height = '5px';
        element.style.height = element.scrollHeight + 'px';
    }

    private isDisabled(): boolean {
        return this.chatbot.state.sending || this.chatbot.state.locked;
    }

    view() {
        return m('.chatbot-input.d-flex.flex-row.flex-nowrap.bg-body-tertiary.w-100.gap-2.p-3', {}, [
            m('textarea.form-control.auto-resize', {
                value: this.isDisabled() ? '' : this.chatbot.state.input,
                disabled: this.isDisabled(),
                placeholder: this.chatbot.translator.translate('chatbot_ask_me'),
                oncreate: vnode => this.updateHeight(<HTMLTextAreaElement>vnode.dom),
                oninput: e => {
                    this.chatbot.state.input = e.currentTarget.value;
                    this.updateHeight(e.currentTarget);
                },
                onkeydown: async (e: KeyboardEvent) => {
                    if (e.key === 'Enter' && !e.shiftKey && this.chatbot.state.input.trim() && !this.isDisabled()) {
                        e.preventDefault();
                        this.chatbot.state.input = this.chatbot.state.input.trim();
                        await this.chatbot.send();
                        this.updateHeight(<HTMLTextAreaElement>e.currentTarget);
                    }
                },

            }),
            m('button.btn.btn-flat-primary', {
                onclick: async e => {
                    e.preventDefault();
                    if (this.chatbot.state.input.trim() && !this.isDisabled()) {
                        await this.chatbot.send();
                    }
                },
                disable: this.isDisabled(),
            }, [
                m('i.bi.bi-send')
            ])
        ]);
    }
}