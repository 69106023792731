import TMessage from "./TMessage";

export default class ChatbotState {
    public messages: TMessage[] = [];
    public locked: boolean = false;
    public input: string = '';
    public avatarPath: string = '';
    public nick: string = '';
    public sending: boolean = false;
    public autoScroll: boolean = true;
    public started = Date.now();
    public animateFirstMessageText = false;
    public animateMessages = true;
}