import m, {Vnode} from "mithril";
import Chatbot from "../Chatbot";
import TMessage from "../model/TMessage";
import decodeHTMLEntities from "../../../../helpers/decodeHtmlEntities";
import htmlSubstring from "../../../../helpers/htmlSubstring";

export default class Messages {

    constructor(
        private readonly chatbot: Chatbot
    ) { }


    private assistantMessage(message: TMessage, animate: boolean): Vnode {
        return m('.chatbot-message.d-flex.flex-row.flex-nowrap.gap-2.mb-3'+(animate?'.chatbot-animate-message':''), {}, [
            m('.chatbot-avatar.avatar', {
                title: 'Tina',
            }),
            m('.message.d-flex.flex-column.flex-grow-1.align-items-start.gap-1.p-2.position-relative', {}, [
                this.assistantMessageContent(message),
                message.completed ? this.copyButton(message) : null,
            ]),
        ]);
    }

    private assistantMessageContent(message: TMessage): Vnode {
        if (message.content === '...') {
            return m('span.chatbot-think', {}, [ m('span', '.'), m('span', '.'), m('span', '.') ]);
        }

        return m.trust(message.content);
    }

    private userMessage(message: TMessage, animate: boolean): Vnode {
        return m('.user-message.d-flex.flex-row-reverse.flex-nowrap.align-self-end.gap-2.mb-3'+(animate?'.chatbot-animate-message':''), {}, [
            m('img.avatar.d-none.d-md-block', {
                src: this.chatbot.state.avatarPath,
                title: this.chatbot.state.nick,
                alt: this.chatbot.translator.translate('avatar'),
            }),
            m('.message.d-flex.flex-column.flex-grow-1.gap-1.p-2', {}, [
                m('.', m.trust(message.content)),
            ])
        ]);
    }

    private copyButton(message: TMessage): Vnode {
        return m('button.btn.btn-sm.btn-flat-primary', {
            title: this.chatbot.translator.translate('copy'),
            onclick: (e: MouseEvent) => {
                e.preventDefault();

                const html = message.content;
                const plain = decodeHTMLEntities(html.replaceAll('\n', '')
                    .replaceAll('<li>', '- ')
                    .replace(/<\/p><\/h3>|<\/h3>|<\/p><\/li>|<\/p>|<\/li>|<\/ol>/ig, '\n')
                    .replace(/(<([^>]+)>)/ig, ''));

                const clipboardItem = new ClipboardItem({
                    'text/html': new Blob([html], { type: 'text/html' }),
                    'text/plain': new Blob([plain], { type: 'text/plain' })
                });

                navigator.clipboard.write([clipboardItem])
                    .then(() => console.log("clipboard.write() Ok"))
                    .catch(error => alert(error))

            },
        }, m('i.bi.bi-copy'));
    }

    /**
     * Get message with animated substring and keep animation go brrrr
     * When animation should not run, it returns null
     */
    private getAnimatedMessage(): TMessage|null {
        if (this.chatbot.state.animateFirstMessageText) {
            const length = Math.round((Date.now() - this.chatbot.state.started) / 20); // 1 symbol every n ms
            const firstMessage = this.chatbot.state.messages[0];
            if (length > firstMessage.content.length) {
                this.chatbot.state.animateFirstMessageText = false;
                return null;
            }

            setTimeout(() => m.redraw(), 32);
            console.log(htmlSubstring(firstMessage.content, length));

            return {
                ...firstMessage,
                content: htmlSubstring(firstMessage.content, length),
                completed: false,
            };
        }

        return null;
    }

    view() {
        const animatedMessage = this.getAnimatedMessage();
        if (animatedMessage) {
            return m('.chatbot-conversation-wrapper.d-flex.flex-column.flex-grow-1.w-100', {}, this.assistantMessage(animatedMessage, false));
        }

        return m('.chatbot-conversation-wrapper.d-flex.flex-column.flex-grow-1.w-100', {
            onupdate: vnode => {
                if (this.chatbot.state.autoScroll) {
                    vnode.dom.scrollTop = vnode.dom.scrollHeight;
                }
            },
            onscroll: e => {
                this.chatbot.state.autoScroll = e.currentTarget.scrollTop >= e.currentTarget.scrollHeight-e.currentTarget.clientHeight;
            },
        }, this.chatbot.state.messages.map((message,i) => {

            if (message.role === 'assistant') {
                return this.assistantMessage(message, this.chatbot.state.animateMessages && i>0);
            }

            return this.userMessage(message, this.chatbot.state.animateMessages && i>0)

        }) );
    }
}
